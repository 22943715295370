var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    { staticStyle: { margin: "0px" } },
    _vm._l(_vm.items, function (item) {
      return _c(
        "b-form-radio",
        {
          key: item.key,
          staticClass: "mb-1",
          attrs: { name: _vm.groupId, value: item.key },
          model: {
            value: _vm.selectedKey,
            callback: function ($$v) {
              _vm.selectedKey = $$v
            },
            expression: "selectedKey",
          },
        },
        [_vm._v("\n    " + _vm._s(item.label) + "\n  ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }