<template>
  <b-form-group style="margin:0px">
    <b-form-radio
      v-for="item in items"
      :key="item.key"
      v-model="selectedKey"
      :name="groupId"
      class="mb-1"
      :value="item.key"
    >
      {{ item.label }}
    </b-form-radio>
  </b-form-group>
</template>

<script>
  /*

  # Radio Group
  Use this component to create a radio group based on an array of JavaScript objects.
  Listen on @changed to observe the state after any user interaction.

  ## Use Case
  The default Bootstrap Vue Radio Button expects its input to be single values, but sometimes its
  more convenient to work with a hash because you can bake in additional data. This also removes
  some of the other overhead that would normally come with setting up a group of radio buttons.

  ## Input
  Provide the list of objects through the 'items' prop. Required fields are 'key' and 'label'.
  items = [
    { key: 'option_1', label: 'Option 1', metadata: 'turtles' },
    { key: 'option_2', label: 'Option 2', whatever: 'something useful' },
  ]

  ## Events
  @change emits the selected object: { key: 'option_1', label: 'Option 1', ... }

  ## Methods
  clearSelection() - Uncheck all the radio buttons in this group.

  */

  export default {
    name: 'RadioGroup',

    props: {
      items: {
        type: Array,
        default: () => [],
      },
      groupId: {
        type: String,
        default: () => {
          // To avoid conflicting names, generate something random (e.g. 'radio-group-xjrdungc').
          return `radio-group-${Math.random().toString(36).slice(-8)}`
        },
      },
    },

    data() {
      return {
        selectedKey: false,
      }
    },

    watch: {
      selectedKey() {
        this.emitChange()
      },
    },

    methods: {
      // Use the radio button's key to look up the corresponding object. Emit object or false.
      emitChange() {
        const filtered = this.items.filter(record => record.key === this.selectedKey)
        const selectedOption = filtered.length > 0 ? filtered[0] : false

        this.$emit('changed', this.groupId, selectedOption)
      },

      clearSelection() {
        this.selectedKey = false
      },
    },
  }
</script>
