<template>
  <b-form-group>
    <b-form-radio
      v-for="filter in dateFilters"
      :key="filter.key"
      v-model="selectedKey"
      name="dateFilters"
      class="mb-1"
      :value="filter.key"
    >
      {{ filter.label }}
    </b-form-radio>
    <v-date-picker
      v-if="isCustomRangeSelected"
      v-model="datePickerVal"
      is-range
      :popover="{ placement: 'top', visibility: 'click' }"
    />
  </b-form-group>
</template>
<script>
  /*

  # Date Range Radio Group
  Use this component to create a radio group of date options (1 week, 1 month, etc) and a 'custom'
  option that creates a DatePicker. Listen on @changed to harvest the chosen date objects after
  any user interaction.

  ## Events
  @change emits the a hash of selected date range: { start: 'JsDateObject1', end: 'JsDateObject2' }

  ## Methods
  clearSelection() - Uncheck all the radio buttons in this group and hide the popup.

  */

  import { getDateFilter } from '@/common/modules/dates'

  const { DateTime } = require('luxon')

  export default {
    name: 'DateRangeRadioGroup',

    props: {
      filters: {
        type: Array,
        default: [
          getDateFilter('UPCOMING_6_MONTHS'),
          getDateFilter('UPCOMING_3_MONTHS'),
          getDateFilter('UPCOMING_1_MONTH'),
          getDateFilter('UPCOMING_1_WEEK'),
          getDateFilter('PAST_1_WEEK'),
          getDateFilter('PAST_1_MONTH'),
          getDateFilter('PAST_3_MONTHS'),
          getDateFilter('PAST_6_MONTHS'),
        ],
      },
      custom: {
        type: Boolean,
        default: true,
      },
      selection: {
        type: String,
        default: null,
      },
      setDefault: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        selectedKey: this.selection,
        dateFilters: this.filters,
        datePickerVal: {
          start: DateTime.local().toJSDate(),
          end: DateTime.local().toJSDate(),
        },
      }
    },

    computed: {
      isCustomRangeSelected() {
        return this.selectedKey === 'custom_date_range'
      },

      // Use the radio button's key to look up the predefined start/end date objects.
      selectedOption() {
        const filtered = this.dateFilters.filter(record => record.key === this.selectedKey)

        // Fire off the startInit and endInit functions (defined above) to generate date objects.
        // Divide timestamp by 1000 to get unix time matching up with server. Save back onto object.
        if (filtered.length > 0 && filtered[0]['startInit']) {
          filtered[0]['startTime'] = filtered[0]['startInit']().ts / 1000
        }
        if (filtered.length > 0 && filtered[0]['endInit']) {
          filtered[0]['endTime'] = filtered[0]['endInit']().ts / 1000
        }

        return filtered.length > 0 ? filtered[0] : false
      },
    },

    watch: {
      selectedKey() {
        this.emitChange()
      },

      datePickerVal() {
        this.emitChange()
      },
    },

    mounted() {
      if (this.custom) {
        this.filters.push({
          label: 'Custom Date Range',
          key: 'custom_date_range',
          startInit: () => DateTime.fromJSDate(this.datePickerVal.start),
          endInit: () => DateTime.fromJSDate(this.datePickerVal.end),
        })
      }

      if(this.setDefault){
        this.selectedKey = this.filters[0].key
        this.emitChange()
      }

      // If a filter was passed in, propagate it's effect
      if(this.selection) {
        this.emitChange()
      }
    },

    methods: {
      emitChange() {
        this.$emit('changed', this.selectedOption)
      },

      clearSelection() {
        this.selectedKey = false
      },
    },
  }
</script>
