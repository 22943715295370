var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "b-input-group",
        { staticClass: "filter-search-area" },
        [
          _vm.hasFilters
            ? _c(
                "b-input-group-addon",
                { attrs: { slot: "prepend" }, slot: "prepend" },
                [
                  _c(
                    "b-button-group",
                    { staticClass: "mr-2" },
                    [
                      _c(
                        "b-dropdown",
                        {
                          ref: "filterDropdown",
                          staticClass: "no-rounded-corners",
                          attrs: { left: "", text: _vm.filterLabel },
                        },
                        [
                          _c(
                            "b-dropdown-form",
                            {
                              style: _vm.dynamicDropdownStyles,
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.onSubmit.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _vm._l(
                                _vm.filterOptions.radioGroups,
                                function (group, index) {
                                  return _vm.radioGroupsExist()
                                    ? _c(
                                        "div",
                                        { key: index },
                                        [
                                          index !== 0
                                            ? _c("b-dropdown-divider")
                                            : _vm._e(),
                                          _c("radio-group", {
                                            ref: "radioGroups",
                                            refInFor: true,
                                            attrs: {
                                              items: group.filters,
                                              "group-id": group.key,
                                            },
                                            on: {
                                              changed: _vm.handleRadioChange,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                }
                              ),
                              _vm.dateRadioGroupExists()
                                ? _c(
                                    "div",
                                    [
                                      _vm.radioGroupsExist()
                                        ? _c("b-dropdown-divider")
                                        : _vm._e(),
                                      _c("date-range-radio-group", {
                                        ref: "dateGroup",
                                        attrs: {
                                          filters:
                                            _vm.filterOptions.dateRadioGroup
                                              .filters,
                                          custom:
                                            _vm.filterOptions.dateRadioGroup
                                              .custom,
                                          selection:
                                            _vm.filterOptions.dateRadioGroup
                                              .selection,
                                        },
                                        on: { changed: _vm.handleDateChange },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm.hasFilter ? _c("b-dropdown-divider") : _vm._e(),
                          _vm.hasFilter
                            ? _c(
                                "b-dropdown-form",
                                {
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                      return _vm.onSubmit.apply(null, arguments)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "b-form-row",
                                    { staticClass: "form-btn-container" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-btn-container-child",
                                          attrs: { cols: "12", md: "8" },
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              attrs: { variant: "link" },
                                              on: { click: _vm.removeFilters },
                                            },
                                            [_vm._v("Remove Filters")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-btn-container-child",
                                          attrs: { cols: "12", md: "4" },
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              attrs: { variant: "primary" },
                                              on: { click: _vm.hideDropdown },
                                            },
                                            [_vm._v("Done")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isNotMobile && _vm.showSearchInput
            ? _c(
                "b-input-group-text",
                { attrs: { slot: "prepend" }, slot: "prepend" },
                [_c("feather-icon", { attrs: { type: "search" } })],
                1
              )
            : _vm._e(),
          _vm.showSearchInput
            ? _c("b-form-input", {
                attrs: {
                  id: "searchField",
                  type: "text",
                  placeholder: "Search",
                },
                model: {
                  value: _vm.query,
                  callback: function ($$v) {
                    _vm.query = $$v
                  },
                  expression: "query",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }