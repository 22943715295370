var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    [
      _vm._l(_vm.dateFilters, function (filter) {
        return _c(
          "b-form-radio",
          {
            key: filter.key,
            staticClass: "mb-1",
            attrs: { name: "dateFilters", value: filter.key },
            model: {
              value: _vm.selectedKey,
              callback: function ($$v) {
                _vm.selectedKey = $$v
              },
              expression: "selectedKey",
            },
          },
          [_vm._v("\n    " + _vm._s(filter.label) + "\n  ")]
        )
      }),
      _vm.isCustomRangeSelected
        ? _c("v-date-picker", {
            attrs: {
              "is-range": "",
              popover: { placement: "top", visibility: "click" },
            },
            model: {
              value: _vm.datePickerVal,
              callback: function ($$v) {
                _vm.datePickerVal = $$v
              },
              expression: "datePickerVal",
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }