<template>
  <span>
    <b-input-group class="filter-search-area">
      <b-input-group-addon v-if="hasFilters" slot="prepend">
        <b-button-group class="mr-2">
          <b-dropdown
            ref="filterDropdown"
            left
            :text="filterLabel"
            class="no-rounded-corners"
          >
            <b-dropdown-form :style="dynamicDropdownStyles" @submit.prevent="onSubmit">
              <div
                v-for="(group, index) in filterOptions.radioGroups"
                v-if="radioGroupsExist()"
                :key="index"
              >
                <b-dropdown-divider v-if="index !== 0" />
                <radio-group
                  ref="radioGroups"
                  :items="group.filters"
                  :group-id="group.key"
                  @changed="handleRadioChange"
                />
              </div>
              <div v-if="dateRadioGroupExists()">
                <b-dropdown-divider v-if="radioGroupsExist()" />
                <date-range-radio-group
                  ref="dateGroup"
                  :filters="filterOptions.dateRadioGroup.filters"
                  :custom="filterOptions.dateRadioGroup.custom"
                  :selection="filterOptions.dateRadioGroup.selection"
                  @changed="handleDateChange"
                />
              </div>
            </b-dropdown-form>

            <b-dropdown-divider v-if="hasFilter" />
            <b-dropdown-form v-if="hasFilter" @submit.prevent="onSubmit">
              <b-form-row class="form-btn-container">
                <div class="form-btn-container-child" cols="12" md="8">
                  <b-button variant="link" @click="removeFilters">Remove Filters</b-button>
                </div>
                <div class="form-btn-container-child" cols="12" md="4">
                  <b-button variant="primary" @click="hideDropdown">Done</b-button>
                </div>
              </b-form-row>
            </b-dropdown-form>
          </b-dropdown>
        </b-button-group>
      </b-input-group-addon>
      <b-input-group-text v-if="isNotMobile && showSearchInput" slot="prepend">
        <feather-icon type="search" />
      </b-input-group-text>
      <b-form-input
        v-if="showSearchInput"
        id="searchField"
        v-model="query"
        type="text"
        placeholder="Search"
      />
    </b-input-group>
  </span>
</template>

<script>
  import FeatherIcon from '@/components/shared/FeatherIcon'
  import _ from 'lodash'
  import RadioGroup from './RadioGroup'
  import DateRangeRadioGroup from './DateRangeRadioGroup'

  export default {
    name: 'Search',
    components: { DateRangeRadioGroup, RadioGroup, FeatherIcon },
    props: {
      filterOptions: {
        type: Object,
        default: () => {
          return {}
        },
      },
      mq: {
        type: String,
        default: '',
      },
      showSearchInput: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      return {
        debounced: _.debounce(this.emitChange, 1000),
        query: '',
        selectedRadioFilters: {},
        selectedDateFilter: this.filterOptions.dateRadioGroup?.default || {},
        selectedDateFilterType: '',
      }
    },

    computed: {
      hasFilters() {
        return this.filterOptions && (this.filterOptions.radioGroups || this.filterOptions.dateRadioGroup)
      },

      // Return "Filters", "Filter A", or "Filters (x)" based on filter count.
      filterLabel() {
        let filterCount = this.allFilters.length
        // Selecting a date range adds two filters, but users see it as one.
        if (this.selectedDateFilter && (this.selectedDateFilter.startTime && this.selectedDateFilter.endTime)) {
          filterCount--
        }
        switch (filterCount) {
          case 0:
            return 'Filters'
          case 1:
            return this.allFilters[0].label
          default:
            return `Filters (${filterCount})`
        }
      },

      dynamicDropdownStyles() {
        let width = this.filterOptions.dateRadioGroup || this.hasFilter ? 256 : 160
        return `width: ${width}px; padding-bottom:0px;`
      },

      isNotMobile() {
        return this.mq !== 'sm'
      },

      hasFilter() {
        return this.allFilters.length > 0
      },

      allFilters() {
        const filters = []

        Object.keys(this.selectedRadioFilters).forEach(key => {
          if (this.selectedRadioFilters[key] !== false) {
            filters.push(this.selectedRadioFilters[key])
          }
        })

        if (this.selectedDateFilter && this.filterOptions.dateRadioGroup) {
          const scope  = this.filterOptions.dateRadioGroup.scope
          const filter = this.selectedDateFilter

          // Default to created_at if no time scope attribute was given
          const startTimeScope = scope ? scope.startField : 'created_at_gte'
          const endTimeScope   = scope ? scope.endField   : 'created_at_lte'
          // Use the needed scope formatter if given, otherwise do nothing
          const formatter = scope && scope.formatter ? scope.formatter : (x) => x

          if (this.selectedDateFilter.startTime) {
            filters.push({ scope: startTimeScope, value: formatter(filter.startTime), key: filter.key, label: filter.label })
          }
          if (this.selectedDateFilter.endTime) {
            filters.push({ scope: endTimeScope, value: formatter(filter.endTime), key: filter.key, label: filter.label })
          }
        }

        return filters
      },
    },

    watch: {
      query() {
        this.debounced()
      },
    },

    methods: {
      hideDropdown() {
        this.$refs.filterDropdown.hide(true)
      },

      handleRadioChange(groupId, selection) {
        this.$set(this.selectedRadioFilters, groupId, selection)
        this.emitChange()
      },

      handleDateChange(selection) {
        // Replace with a clone so that we always trigger a Vue update.
        this.selectedDateFilter = Object.assign({}, selection)
        this.emitChange()
      },

      removeFilters() {
        if (this.$refs.radioGroups) {
          this.$refs.radioGroups.forEach(item => {
            item.clearSelection()
          })
        }

        if (this.$refs.dateGroup) {
          this.$refs.dateGroup.clearSelection()
        }
      },

      emitChange() {
        this.$emit('changed', {
          query: this.query,
          filters: this.allFilters,
        })
      },

      radioGroupsExist() {
        return this.filterOptions.radioGroups && this.filterOptions.radioGroups.length > 0
      },

      dateRadioGroupExists() {
        return this.filterOptions.dateRadioGroup && (this.filterOptions.dateRadioGroup.filters || this.filterOptions.dateRadioGroup.custom)
      },
    },
  }
</script>

<style lang="scss" scoped>
  input#searchField.form-control {
    height: calc(1.5em + 0.75rem + 3px) !important;
  }

  .filter-search-area {
    justify-content: flex-end;
  }

  @media only screen and (max-width: 660px) {
    .filter-search-area {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-content: center;

      #searchField {
        width: 100%;
      }
    }

    .form-btn-container {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-content: center;
      row-gap: 1.0em;
    }

    .form-btn-container-child {
      width: 100% !important;

      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;

      button {
        margin-bottom: 1.0em;
      }
    }
  }
</style>
