<template>
  <span>
    <template v-if="groupButtons">
      <div v-if="$slots.default && $slots.default.length > 0"
           class="toolbar-row-wrap pt-2 mt-0"
      >
        <slot />
      </div>
    </template>
    <template v-else>
      <div v-if="$slots.default && $slots.default.length > 0"
           class="toolbar-row-nowrap fixed-btn-group pt-2 mt-0"
      >
        <b-button-group class="mt-2 fixed-btn-group right">
          <slot />
        </b-button-group>
      </div>
    </template>

    <hr v-if="$slots.default && Object.keys($slots).length > 0" class="w-100 mb-4">
    <b-row :class="contentClasses">
      <b-col md="6" sm="12 pb-2">
        <div class="super-title">
          {{ superTitle }}
        </div>
        <h2 data-cy="title">
          {{ title }}
          <slot name="title-icon" />
        </h2>
      </b-col>
      <b-col md="6" sm="12">
        <search v-if="showSearch"
                :show-search-input="showSearchInput"
                :datasource="datasource"
                :fields="fields"
                :filter-options="filterOptions"
                :mq="$mq"
                @changed="searchChanged"
        />
      </b-col>
    </b-row>
  </span>
</template>

<script>
  import Search from '@/components/shared/Search'

  export default {
    name: 'Masthead',
    components: { Search },
    props: {
      superTitle: {
        type: String,
        default: '',
      },
      title: {
        type: String,
      },
      groupButtons: {
        type: Boolean,
        default: true,
      },
      datasource: {
        type: Array,
        default: () => [],
      },
      fields: {
        type: Array,
        default: () => [],
      },
      filterOptions: {
        type: Object,
        default: () => {},
      },
      selection: {
        type: Array,
        default: () => [],
      },
      showSearch: {
        type: Boolean,
        default: true,
      },
      showSearchInput: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      contentClasses() {
        let result = 'mb-4 '
        if (
          (this.$slots && Object.keys(this.$slots).length === 0) ||
          !this.$slots

        ) {
          result = `${result} mt-4`
        }
        return result
      },
    },
    methods: {
      searchChanged(searchObject) {
        this.$emit('changed', searchObject)
      },
    },
  }
</script>

<style lang="scss" scoped>
  h2 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .super-title {
    font-size: 1em;
    text-transform: uppercase;
    color: $ct-ui-font-color-secondary;
    font-weight: $ct-ui-font-weight-9;
    letter-spacing: 1.2px;
  }

  .right {
    text-align: right;
  }

  @media only screen and (max-width: 660px) {
    .toolbar-row-nowrap {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
    }

    .toolbar-row-wrap {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      row-gap: 1.0em;
    }
  }
</style>
